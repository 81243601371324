.root {
  display: flex;
}
.navigation {
  width: 290px;
  height: 100vh;
}
.container {
  max-width: 1024px;
  border-left: 1px solid #2729371F;
  padding: 96px 60px;
  flex: 1 auto;
}