.root {}
.label {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
}
.field {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #2729371F;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.field:focus {
  outline: none;
}
.error {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #FF3A30;
}