.root {
  display: flex;
  margin-bottom: 48px;
}
.tab {
  min-width: 96px;
  padding: 10px 20px;
  border-bottom: 1px solid #E5E5E7;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.selected {
  border-bottom-color: #05ADBF;
  color: #05ADBF;
}