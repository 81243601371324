.root {
  width: 38px;  height: 24px;
  border-radius: 12px;
  background-color: #2729371F;
  position: relative;
  display: inline-flex;
  font-size: 0;
  cursor: pointer;
  vertical-align: top;
}
.toggle {
  width: 16px; height: 16px;
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 4px; left: 4px;
}
.checked {
  background-color: #FFB649;
}
.checked > .toggle {
  left: auto; right: 4px;
}
.disabled {
  cursor: default;
  opacity: 0.5;
}