.container {
  border: 1px solid #E5E5E7;
  border-radius: 10px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.thead {
  vertical-align: top;
}
.thead > tr > th {
  border-bottom: 1px solid #E5E5E7;
  font-weight: 500;
}
.thead > tr > th,
.tbody > tr > td {
  padding: 16px;
  line-height: 24px;
  font-size: 16px;
  text-align: left;
}
.tbody > tr > td {
  vertical-align: top;
}
.tbody > tr + tr > td {
  border-top: 1px solid #E5E5E7;
}
.placeholder {
  padding: 16px;
  line-height: 24px;
  font-size: 16px;
}
.sortable {
  display: inline-flex;
  cursor: pointer;
}
.sortable > span {
  font-size: 16px;
}
.sortable > svg {
  width: 24px; height: 24px;
  fill: #27293759;
  opacity: 0.5;
}
.sortable.active > svg,
.sortable:hover > svg {
  opacity: 1;
}