.root {
  padding-left: 32px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.label {
  line-height: 24px;
  font-size: 16px;
  display: block;
}
.control {
  position: absolute;
  top: 0; left: 0;
}
.control > span {
  box-sizing: border-box;
  width: 20px; height: 20px;
  border-radius: 10px;
  border: 1px solid #27293759;
  position: absolute;
  top: 2px; left: 0;
}
.control > span:after {
  content: '';
  width: 10px; height: 10px;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  transform: translate(4px, 4px);
}
.control > [type="radio"] {
  visibility: hidden;
}
.control > [type="radio"]:checked + span {
  background-color: #05ADBF;
  border-color: #05ADBF;
}