.root {
  position: fixed;
  z-index: 1300;
  background-color: rgba(255, 255, 255, 0.8);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  box-sizing: border-box;
  width: 390px;
  padding: 40px 40px 60px;
  border: 1px solid #2729371F;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.close {
  position: absolute;
  top: 16px; right: 16px;
  cursor: pointer;
  font-size: 0;

}
.close > svg {
  width: 32px; height: 32px;
  fill: #05ADBF;
}