.link {
  padding: 14px 20px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  background-color: #07C1D61A;
}
.link > span {
  display: flex;
}
.link > span > svg {
  width: 24px; height: 24px;
  margin-left: -6px;
  margin-right: 10px;
  fill: currentColor;
}
.primary, .outlined {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.primary {
  background-color: #05ADBF;
  color: #fff;
}
.outlined {
  border: 1px solid #27293759;
  color: #272937;
}
.group {
  display: flex;
}
.group > * + * {
  margin-left: 16px;
}