.root {
  margin-top: 32px;
}
.label {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}
.table {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cell {
  text-align: center;
  line-height: 24px;
}
.cell > span {
  display: block;
  font-size: 12px;
  line-height: 16px;
}
.loader {
  line-height: 40px;
}