.root {
  padding: 32px 16px;
}
.brand {
  margin-bottom: 32px;
  font-size: 0;
}
.brand > svg {
  width: 122px; height: 32px;
}
.btn {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.isActive {
  font-weight: 500;
  background-color: #07C1D61A;
  color: #05ADBF;
}
.btn > svg {
  width: 24px; height: 24px;
  fill: currentColor;
}
.btn > span {
  margin-left: 10px;
}
.logout {
  margin-top: 32px;
}