.root {
  width: 100vw; height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
}
.brand {
  width: 122px; height: 32px;
  margin: 64px auto 128px auto;
  display: block;
}
.form {
  width: 456px;
}
.heading {
  line-height: 36px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.control {
  box-sizing: border-box;
  width: 100%;
  padding: 14px 20px;
  border: 1px solid #2729371F;
  border-radius: 8px;
  line-height: 24px;
  color: #272937;
}
.control:focus {
  outline: none;
}
.btn {
  width: 100%;
  padding: 14px 0;
  border: 0;
  border-radius: 8px;
  background-color: #05ADBF;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #fff;
}
.btn:disabled {
  opacity: 0.5;
}
.addon {
  margin-top: 8px;
}
.stack {
  margin-top: 64px;
  margin-bottom: 64px;
}
.stack > input + input {
  margin-top: 16px;
}