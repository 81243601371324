.root {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.input {
  margin-right: 32px;
  display: flex;
  align-items: center;
}
.label {
  margin-right: 16px;
  color: #27293759;
}
.select {
  width: 120px; height: 100%;
  padding-left: 16px;
  border: 1px solid #E5E5E7;
  border-radius: 8px;
  display: block;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.41707 10L12.0029 13.5858L15.5887 10C15.9792 9.60952 16.6123 9.60952 17.0029 10C17.0517 10.0489 17.0944 10.1015 17.131 10.1569C17.3873 10.5451 17.3446 11.0726 17.0029 11.4143L12.71 15.7072C12.5224 15.8947 12.2681 16 12.0029 16C11.7376 16 11.4833 15.8947 11.2958 15.7072L7.00285 11.4143C6.61233 11.0237 6.61233 10.3906 7.00285 10C7.05167 9.95123 7.10428 9.90852 7.15973 9.87191C7.54788 9.61563 8.07536 9.65834 8.41707 10Z' fill='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 16px center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer
;
}
.select:focus {
  outline: none;
}
.group {
  border: 1px solid #E5E5E7;
  border-radius: 8px;
  display: flex;
}
.divider {
  width: 1px; height: 24px;
  transform: translateY(14px);
  background-color: #2729371F;
}
.btn {
  padding: 12px;
  cursor: pointer;
  font-size: 0;
}
.btn > svg {
  width: 24px; height: 24px;
  fill: #000;
}